import api from '@/apis/api'
/**
 * 供应商用户相关api
 */
export class ManufacturerAddApi extends api {

  /**
   * 创建新的管理员
   * @param {*} 对象内容
   */
  insert (form) {
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/register`,
      method: 'post',
      data: form
    })
  }
  /**
     * 获取型号分页数据
     * @param {*} 分页请求数据
     */
  getModelPage({
    pageInfo,
    condition
  }) {
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/modelPage`,
      method: 'post',
      pageInfo,
      data: condition
    })
  }
  /**
   * 获取角色分页数据
   * @param {*} 分页请求数据
   */
   getRolePage({
    pageInfo,
    condition
  }) {
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/rolePage`,
      method: 'post',
      pageInfo,
      data: condition
    })
  }
  verifyCode (code) {
    return this.request({
        url: `${this.servicePath}/${this.modulePath}/verifyCode/${code}`,
        method: 'get'
    })
  }
}

export default new ManufacturerAddApi('manufacturerAdd')
